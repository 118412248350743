// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

/* Backgrounds */

.bg-custom-01 {
    background-color: #0D47A1;
}
.bg-custom-02 {
    background: rgba(236, 239, 241, 1);
}
.bg-custom-03 {
    background: #e3f2fd;
}

/* Navbar */

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.navbar {
    padding: 0 1rem;
}

.navbar-brand {
    font-size: 1rem;
    padding: 0;
    font-weight: bold;
}

.navbar-toggler {
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
}
.navbar-nav {
    font-weight: bold;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 1rem 1.25rem;
}

.navbar-nav-main .nav-link {
    padding: 1rem;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-radius: 0;
    color: #fff;
}
.navbar-nav-main .active .nav-link {
    background-color: transparent;
    border-bottom-color: #42A5F5;
}

.nav-pills {
    .show > .nav-link {
        color: $blue;
        background-color: $table-hover-bg;
    }
}


/* Form */

.form-control::placeholder {
    color: #B0BEC5;
}

.form-control:focus {
    box-shadow: none;
    border-color: #ced4da;
}

/* Table */

.table th, .table td,
.table thead th {
    vertical-align: middle;
}
.table tr.active td {
    background-color: $table-hover-bg;
}

.table th.active,
.table td.active {
    background-color: $table-hover-bg;
}

/* Sizing */

.w-1 {
    width: 1% !important;
}
.min-w-100 {
    min-width: 100% !important;
}

/* Responsive */

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .navbar {
        padding: 1rem;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding: .5rem 0;
        border: 0;
    }

    .navbar-nav-main {
        margin-top: 1rem;
        padding-top: 0.25rem;
        border-top: 1px solid rgba(255, 255, 255, .25);
    }

    .navbar-nav-user {
        margin-top: .25rem;
        padding-top: 0.25rem;
        border-top: 1px solid rgba(255, 255, 255, .25);
    }

    //.subject-comment {
    //    font-size: 14px;
    //    color: #607D8B;
    //}
    //.subject-grade {
    //    text-align: right;
    //}

}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}
